import logo from './blob.png';
import './App.css';

function App() {
  return (
    <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>Irene, Gavin, Ella, and Nick</p>
    </header>
  </div>
  );
}

export default App;
